// stage_constants.js

export const isProd = false;

export const urls = {
    login_url: "https://capchd.auth.us-west-1.amazoncognito.com/login?client_id=33pko4bpf1qrsdkqidi4mmarsh&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://stage-capchd.sdsc.edu/",
    identify_pool_id: "us-west-1:3b1e11b4-ae3a-40a7-8f67-9f173b90ed3f",
    cognito_url: 'cognito-idp.us-west-1.amazonaws.com/us-west-1_1Nvwa50B4',
    base_url_query_table: "https://3fikwhtk3i.execute-api.us-west-1.amazonaws.com/Prod/query_tables", //query dynamodb
    base_url: 'https://3fikwhtk3i.execute-api.us-west-1.amazonaws.com/Prod', //count-studies-app
    base_download_url: 'https://3fikwhtk3i.execute-api.us-west-1.amazonaws.com/Prod/download?',     //capchd_dicom_download
    base_url_upload_tab: 'https://3fikwhtk3i.execute-api.us-west-1.amazonaws.com/Prod/update_tab',
    study_bucketName: 'capchd-dicom-stage-db',
    csv_bucketName: 'capchd-csv-stage-bucket',
    data_usage_agreement_bucketName: 'capchd-data-usage-agreement-stage-bucket',
};

export const tableNames = {
    patient: 'stage_Patient',
    cmr: 'stage_CMR',
    cath: 'stage_Cath',
    cpet: 'stage_CPET',
    ecg: 'stage_ECG',
    echo: 'stage_ECHO',
    procedure: 'stage_Procedure',
    clinicalnotes: 'stage_ClinicalNotes'
};

export const validCognitoGroups = ['capchd-admin', 'capchd-download', 'capchd-edit', 'capchd-upload'];