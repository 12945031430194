import { TextField, Button, Grid, Typography } from "@material-ui/core";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import React, { useState } from "react";

import AWS from 'aws-sdk';

const DataUsageAgreementUpload = (props) => {
    const [centerName, setCenterName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [username, setUsername] = useState('');
    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState('');
    const [error, setError] = useState('');

    const validateFields = () => {
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (!centerName || !startDate || !username || !file) {
            setError("Please complete all fields and upload a file.");
            return false;
        }
        if (!dateRegex.test(startDate)) {
            setError("Start date must be in the format mm/dd/yyyy.");
            return false;
        }
        if (file && file.type !== "application/pdf") {
            console.log(`Incorrect file type: ${file.type}`)
            console.log("Field validation error");
            setError("Only PDF files are allowed.");
            return false;
        }
        setError('');
        return true;
    };

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === "application/pdf") {
            setFile(selectedFile);
            setError('');
        } else {
            setError("Only PDF files are allowed.");
            setFile(null);
        }
    };
    
    const handleSubmit = async () => {
        if (!validateFields()) return;
        
        // Upload pdf to s3 directly
        const key = `${centerName}.pdf`;
        const success = await props.uploadToS3(file, key);

        if (success !== 200) {
            setError("Error uploading pdf. Please try again.");
            return;
        }
        
        try {
            const response = await fetch(`${props.baseUrl}/data_usage_agreement`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": props.authToken,
                },
                body: JSON.stringify({
                    Center: centerName,
                    StartDate: startDate,
                    Username: username,
                    fileName: key,
                }),
            });
            
            if (response.ok) {
                props.setOpenAlert(true);
                setUploadSuccess("Data usage agreement successfully uploaded. Please refresh.");
                window.location.reload();
                setError('');
            } else {
                setError("Error updating table. Please try again.")
                console.error("Error updating table:", response.statusText);
            }
        } catch (error) {
            console.error("Error in upload:", error);
        }
    };

    
    

    const newLocal = true;
    return (
        <div style={{ backgroundColor: 'rgb(235, 239, 241)',
            height: '100%',
            width: '100%',
            marginTop: 40,
            paddingLeft: 60,
            paddingRight: 60,
            display: "flex",
            "flex-direction": "column",
            "align-items": "left",
        }}>
            <h4>Upload a Data Usage Agreement</h4>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {uploadSuccess && <p style={{ color: 'green' }}>{uploadSuccess}</p>}
            <Grid item style={{ marginTop: 20 }}>
                <TextField
                    label="Center Name"
                    variant="outlined"
                    onChange={(e) => setCenterName(e.target.value)}
                    style={{ width: 400, backgroundColor: 'white' }}
                />
            </Grid>
            <Grid item style={{ marginTop: 20 }}>
                <TextField
                    InputLabelProps={{shrink: true}}
                    label="Start Date (mm/dd/yyyy)"
                    variant="outlined"
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    style={{ width: 400, backgroundColor: 'white' }}
                />
            </Grid>
            <Grid item style={{ marginTop: 20 }}>
                <TextField
                    label="Username"
                    variant="outlined"
                    onChange={(e) => setUsername(e.target.value)}
                    style={{ width: 400, backgroundColor: 'white' }}
                />
            </Grid>
            <Grid item style={{ marginTop: 20 }}>
                <div style={{
                    border: 'grey dashed',
                    backgroundColor: "white",
                    paddingTop: 30,
                    paddingBottom: 30,
                    paddingLeft: 150,
                    paddingRight: 150,
                    borderRadius: 10,
                    width: 'fit-content',
                    cursor: 'pointer'
                }}>
                    <input
                        type="file"
                        accept="application/pdf"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        id="file-upload"
                    />
                    <label htmlFor="file-upload" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                        <FileUploadOutlinedIcon style={{ fontSize: 30, color: '#009be5' }} />
                        <Typography variant='h5' style={{ fontSize: 12, textTransform: 'none', color: 'black' }}>
                            Click to browse files
                        </Typography>
                    </label>
                    {file && <p>File selected: {file.name}</p>}
                </div>
            </Grid>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ marginTop: 20, width: 200 }}
            >
                Submit
            </Button>
        </div>
    );
}

export default DataUsageAgreementUpload;
