import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGridPro,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopParams,
  GridRowEditStopReasons,
  GridRowEditStartParams,
  GridRowEditStartReasons,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import Select from '@mui/material/Select';
import { useCallback, useEffect, useRef, useState } from "react";
import {urls} from "../constants";
import { GridToolbarExport, useGridApiRef } from "@mui/x-data-grid-pro";
import ReactLoading from 'react-loading';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import AlertTitle from '@mui/material/AlertTitle';
import MenuItem from '@mui/material/MenuItem';
import { Theme, useTheme } from '@mui/material/styles';
import React from "react";
import { FormControl } from "@material-ui/core";

//import nextId from "react-id-generator";
import InputLabel from '@mui/material/InputLabel';
const base_url_query = urls.base_url_query_table;
const base_url = urls.base_url

/*
const tempRows = [
    {Username: 'jsmithmanrique', Email: 'jos016@sdsc.edu', "Number of groups": '4', Groups: ["Edit", "Admin"]},
    {Username: 'Tester123', Email: 'testPerson@sdsc.edu', "Number of groups": '1', Groups: []}
]
*/


const groups = [['Edit', "capchd-edit"], ['Admin', "capchd-admin"], ['Download', "capchd-download"], ['Upload', "capchd-upload"]];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1, fontSize: 14 }}>No Records Found</Box>
      </StyledGridOverlay>
      );
    }

  function CustomUnsortedIcon() {
    return <UnfoldMoreIcon sx={{fontSize: 20, color: "#009be5"}}/>;
  }

  function ColumnSortedAscendingIcon() {
    return  <ArrowUpwardIcon sx={{fontSize: 20, color: "#009be5"}}/>;
  }

  function ColumnSortedDescendingIcon() {
    return <ArrowDownwardIcon  sx={{fontSize: 20, color: "#009be5"}} />
  }

  function getStyles(name, value, theme) {
    return {
      fontWeight:
        value.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

export function DataUsageAgreementTable(props) {
  const [rowModesModel, setRowModesModel] = useState({});
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [promiseArguments, setPromiseArguments] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [editCell, setEditCell] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [promiseArgumentsDelete, setPromiseArgumentsDelete] = React.useState(null);
  let [remove, setRemove] = React.useState(true);
  const apiRef = useGridApiRef();
  const theme = useTheme();

  function CustomToolbar() {
  
    return (
      <GridToolbarContainer>
      </GridToolbarContainer>
    );
  }

  function SelectEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    let current = editCell;
    {/*
  const handleLastRemove = (event) => {
      let val = event.target.value;
      console.log(current.length);
      if(current.length == 1 && remove){
        current = current.filter(element => element != val);
        setEditCell(current);
        apiRef.current.setEditCellValue({ id, field, value: current });
        setRemove(false);
      }
    }
    
    */}
    
    const handleChange = (event) => {
      let val = event.target.value;

      let index = current.indexOf(val);

      /*
      if(index > -1) {
        current.splice(index, 1);
        //setEditCell(current);
      } else {
        current.push(val);
        //setEditCell(current);
      }
      */
      
      current = val;
      //console.log(current);
      setEditCell(current);
      apiRef.current.setEditCellValue({ id, field, value: current });
    };

    const handleClose = (event) => {
      //console.log(event);
    }
  
    return (
      <FormControl>
        <Select
          labelId="Groups"
          //open="true"
          value={value}
          onChange={handleChange}
          //onClose={handleClose}
          renderValue={(selected) => selected.join(', ')}
          size="medium"
          sx={{width: 250 }}
          autoFocus
          multiple
          MenuProps={MenuProps}
        >
          {groups.map((name) => (
            <MenuItem /*onClick={() => {handleChange(name[1])}} */ key={name[0]} value={name[0]}>
              <Checkbox checked={value.indexOf(name[0]) > -1} />
              <ListItemText primary={name[0]} />
            </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }

  const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
  };

  const setNumGroups = (params) => {
    return params.row["Groups"].length;
  }

    
    const reRenderTable = () => {
      const sColumns = [
        { field: 'Center', headerName: 'Center', width: 150, editable: false },
        { field: 'StartDate', headerName: 'Start Date', width: 150, editable: false },
        { field: 'User', headerName: 'User', width: 150, editable: false },
        { 
          field: 'pdfLink', 
          headerName: 'Link to Agreement', 
          width: 150, 
          editable: false,
          renderCell: (params) => {
            const link = params.value;
            
            if (!link) {
              console.log(params.value);
              //console.warn("Invalid pdfLink:", params);
              return <span style={{ color: 'red' }}>No Link</span>;
            }

            return (
                <a 
                  href={link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ color: '#1a0dab', textDecoration: 'underline' }}
                >
                  Download Agreement
                </a>
            );
        }
      }
      ];

      setColumns(sColumns);
    }


    const renderTable = () => {
      let users_URL = urls.base_url + "/data_usage_agreement?";
      let params = {};
      users_URL = users_URL + new URLSearchParams(params);
      console.log(`Fetching table data at url: ${users_URL}`);
      fetch(users_URL, {headers: { Authorization: props.authToken }, method: 'GET'})
        .then((response) => { 
          setLoading(false);
          if(!response.ok){
            throw Error(response.statusText);
          }
          return response.json()
        })
        .then(data => {
          console.log(data);
          const sColumns = [
            { field: 'Center', headerName: 'Center', width: 150, editable: false },
            { field: 'StartDate', headerName: 'Start Date', width: 150, editable: false },
            { field: 'User', headerName: 'User', width: 150, editable: false },
            { 
              field: 'pdfLink', 
              headerName: 'Link to Agreement', 
              width: 150, 
              editable: false,
              renderCell: (params) => {
                const link = params.value;
                
                if (!link) {
                  console.log(params.value);
                  //console.warn("Invalid pdfLink:", params);
                  return <span style={{ color: 'red' }}>No Link</span>;
                }

                return (
                    <a 
                      href={link} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      style={{ color: '#1a0dab', textDecoration: 'underline' }}
                    >
                      Download Agreement
                    </a>
                );
            }
          }
        ];
          setRows(data);
          setColumns(sColumns);
        })
        .catch(err => {
          console.log(err);
          setSnackbar({ children: 'Unable to load users. Please contact site Admin', severity: 'error', title: "Error" });
          setColumns([]);
          setRows([]);
        })
    }

    const handleCreateUser = (e) => {
      props.handleLinking(e, "Create User");
    }

    const handleSaveClick = (id) => {
      props.setEditing(false);
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id) => {
      //console.log(rows);
      setRowModesModel({...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true }});
      props.setEditing(false);
    }

    const handleDeleteClick = React.useCallback((id) => 
    new Promise((resolve, reject) => {
      let row = apiRef.current.getRow(id);
      setPromiseArgumentsDelete({resolve, reject, row})
    }),
    [])

    const handleEditClick = (id) => {
      let currentModel = {...rowModesModel};
      //console.log(id);
      let rows = Object.keys(currentModel);
      let edit = true;
      if(rows.length >= 1) {
        //console.log(rows);
        rows.forEach((element) => {
          //console.log(element);
          if(currentModel[element].mode == GridRowModes.Edit) {
            props.setOpenAlert(16);
            edit = false;
          }
        });
      }
      if(edit){
        props.setEditing(true);
        currentModel[id] = {mode: GridRowModes.Edit};
        setRowModesModel(currentModel);
        let cell = apiRef.current.getCellValue(id, "LinkToAgreement")
        //console.log(cell);
        setEditCell(cell);
      }
      //console.log(currentModel);
    }

    const computeMutation = (newRow, oldRow) => {
      return true;
    }

    /*
    const calculateAccess = (newRow) => {
      if(newRow["Groups"].length <= 0){
        return "none of the";
      } else {
        return newRow["Groups"].toString();
      }
    }
    */

    const handleConfirmEdit = React.useCallback((newRow, oldRow) =>
    new Promise((resolve, reject) => {
      if(oldRow) {
        //console.log(newRow);
        //console.log(oldRow);
        //converting numeric age to string
        let mutation = computeMutation(newRow, oldRow);
        //console.log(mutation);
        if(mutation){
          //console.log(mutation);
          setPromiseArguments({resolve, reject, newRow, oldRow})
        } else {
          resolve(oldRow);
        }
      }
    }),
    [])

    const handleCloseSnackbar = () => setSnackbar(null);

    const renderConfirmDialog = () => {
      if (!promiseArguments) {
        return null;
      }
  
      return (
        <Dialog
        maxWidth="xs"
        open={!!promiseArguments}
        sx={{".MuiDialogContent-dividers": {color: "white"}}}
        >
        <DialogTitle sx={{fontSize: 16, backgroundColor: "#081627", color: "white", borderBottomColor: "white", borderBottomWidth: "1px", borderBottomStyle: "solid" }}>Are you sure you want to save this row?</DialogTitle>
        <DialogContent sx={{backgroundColor: "#081627", color: "white", borderBottomColor: "white", borderBottomWidth: "1px", borderBottomStyle: "solid"}} dividers>
          {`Pressing 'Yes' will update the table.`}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#081627", }}>
          <Button onClick={handleNo} sx={{color: "white", fontSize: 14}}>
            No
          </Button>
          <Button onClick={handleYes} sx={{color: "white", fontSize: 14}}>Yes</Button>
        </DialogActions>
        </Dialog>
      );
    };

    const renderConfirmDialogDelete = () => {
      if (!promiseArgumentsDelete) {
        return null;
      }
  
      const { row } = promiseArgumentsDelete;
      let mutation = row.Center;

  
      return (
        <Dialog
        maxWidth="xl"
        open={!!promiseArgumentsDelete}
        sx={{".MuiDialogContent-dividers": {color: "white"}}}
        >
        <DialogTitle sx={{fontSize: 16, backgroundColor: "#081627", color: "white", borderBottomColor: "white", borderBottomWidth: "1px", borderBottomStyle: "solid" }}>Are you sure you want to delete this row?</DialogTitle>
        <DialogContent sx={{backgroundColor: "#081627", color: "white", borderBottomColor: "white", borderBottomWidth: "1px", borderBottomStyle: "solid"}} dividers>
          {`Pressing 'Yes' will delete the table item for: ${mutation}`}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#081627", }}>
          <Button onClick={handleNoDelete} sx={{color: "white", fontSize: 14}}>
            No
          </Button>
          <Button onClick={handleYesDelete} sx={{color: "white", fontSize: 14}}>Yes</Button>
        </DialogActions>
        </Dialog>
      );
    };

    const handleYes = async () => {
      const { newRow, oldRow, reject, resolve } = promiseArguments;
        // Make the HTTP request to save in the backend
        let center = newRow.Center;
        let startDate = newRow.StartDate;
        let user = newRow.User;
        let linkToAgreement = newRow.LinkToAgreement;
        let id = newRow.ID;
        console.log(`id: ${id}`);
        
        let add_url = urls.base_url + `/data_usage_agreement/${id}`;
        let params = {"Center": center, "StartDate": startDate, "User": user, "LinkToAgreement": linkToAgreement};
  
        //console.log(newRowJSON);
        
        setPromiseArguments(null);
        setLoading(true);
        fetch( add_url, { headers: { Authorization: props.authToken, 'Content-Type': 'application/json'}, method: 'PATCH', body: JSON.stringify(params)})
        .then(response => {
          if(!response.ok){
            throw Error(response.statusText);
          } else {
            return response.json();
          }
        })
        .then( data => {
          //console.log("delete data:", data);
          setLoading(false);
          resolve(newRow);
          setSnackbar({ children: 'Successfully saved', severity: 'success', title: "Success" });
        })
        .catch((err)=> {
          //console.log(err);
          setLoading(false);
          setSnackbar({ children: "There was an error with saving", severity: 'error', title: "Error" });
          resolve(oldRow);
        })

  
    };

    const handleNo = () => {
      const { oldRow, resolve } = promiseArguments;
      resolve(oldRow); // Resolve with the old row to not update the internal state
      setPromiseArguments(null);
    };

    const handleYesDelete = () => {
      const { row, reject, resolve } = promiseArgumentsDelete;
      //console.log(row);
      let username = row.Username;
      let deletedRow = row;
      let deleteURL = urls.base_url + "/user_management?";
      let params = {"cmd":"delete_user", "username":username};
      deleteURL = deleteURL + new URLSearchParams(params);
  
      setLoading(true);
      setPromiseArgumentsDelete(null);
      fetch( deleteURL, { headers: { Authorization: props.authToken }, method: 'GET'})
      .then(response => {
        //console.log(response);
        if(!response.ok){
          throw Error(response.statusText);
        }
      })
      .then(data => {
        setLoading(false);
        setRows(rows.filter((row) => row.Username !== deletedRow.Username));
        resolve(row);
        setSnackbar({ children: 'Successfully deleted.', severity: 'success', title: "Success" });
      })
      .catch((err)=> {
        //console.log(err);
        setLoading(false);
        setSnackbar({ children: "There was an error with deletion", severity: 'error', title: "Error" });
        resolve(row);
      })
    }
  
    const handleNoDelete = () => {
      const { row, resolve } = promiseArgumentsDelete;
      resolve(row); 
      setPromiseArgumentsDelete(null);
    };

    useEffect(() => {
      renderTable();
    }, [])
  
    useEffect(() => {
      //console.log("Re-render Table");
      if(rows !== undefined){
        reRenderTable();
      }
    }, [rowModesModel, rows]);

    return (
    <div style={{ height: "auto", width: "100%", marginBottom: 30 }} >

      <Typography variant="h5" gutterBottom>
        Data Usage Agreements 
      </Typography>
      
      <div >
        {renderConfirmDialog()}
        {renderConfirmDialogDelete()}
        <DataGridPro
          apiRef={apiRef}
          loading={loading}
          columns={columns}
          rows={rows}
          rowModesModel={rowModesModel}
          processRowUpdate={handleConfirmEdit}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          experimentalFeatures={{ newEditingApi: true }}
          rowsPerPageOptions={[10, 25, 50]}
          initialState={{ pinnedColumns: { left: [], right: [] } }}
          getRowId={(row) => row["ID"]}
          editMode="row"
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
            ColumnUnsortedIcon: CustomUnsortedIcon,
            ColumnSortedAscendingIcon: ColumnSortedAscendingIcon,
            ColumnSortedDescendingIcon: ColumnSortedDescendingIcon
          }}
        />

      {!!snackbar && (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open onClose={handleCloseSnackbar} autoHideDuration={6000} >
          <Alert {...snackbar} sx={{"& .MuiAlert-icon": { fontSize: 40 }}} onClose={handleCloseSnackbar} style={{fontSize: 16}}>
            <AlertTitle  style={{fontSize: 20,  }}>{snackbar.title}</AlertTitle>
            {snackbar.children}
          </Alert>
        </Snackbar>
        )}
      </div>

    </div>
    )
}