/*
 * Contributors: Fred Lionetti, Jonathan Smith
 *
 * Libraries used: MUI React, MUI X
 *
 * File description: Contains functionality for rendering each of the Patient tables
 */
import { makeStyles } from "@material-ui/core/styles";
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from "react";
import { tableNames, urls } from "./constants";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import ReactLoading from 'react-loading';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import AlertTitle from '@mui/material/AlertTitle';
import React from "react";
import { SaveAlt } from "@material-ui/icons";

import table_columns from "./table_columns";

const base_url_query = urls.base_url_query_table;

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

/**
* SVG Icon that appears when no records are found for a table
*
* @params none
* @return renders Icon with No Records Found Text 
*/
function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1, fontSize: 14 }}>No Records Found</Box>
    </StyledGridOverlay>
  );
}

const valid_tables = Object.values(tableNames);

const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250, 500, 1000];

/**
* Contains updateTable function as well as helper call functions that parse through an
* array of values to fill the rows and columns of the datagrid pro component
*
* @params table_name: current table , set_patient_id: patient being called or "Pateint" if none, authToken: current user authorization token
  logoutCallback: call to logout function, setNavTabs: current page number, setHeaderTitle: header of current page
* @return renders single or list of tables corresponding to a pateint or table type
*/
export function MyTable(props) {
  // declare defaults and constants
  const defaultPaginationModel = {
    page: 0,
    pageSize: 10,
  };
  const defaultSorting = "ID asc";

  // declare react state
  const [snackbar, setSnackbar] = React.useState(null);

  const [tableName, setTableName] = useState(props.table_name);
  const [count, setCount] = useState("<loading>");

  const [orderedColumns, setOrderedColumns] = React.useState([]);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [rowModesModel, setRowModesModel] = useState({});
  const [paginationModel, setPaginationModel] = useState(defaultPaginationModel);
  
  const [showTitle, setShowTitle] = useState(false);
  const [sorting, setSorting] = React.useState(defaultSorting);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const noButtonRef = React.useRef(null);
  const [promiseArguments, setPromiseArguments] = React.useState(null);
  const [promiseArgumentsDelete, setPromiseArgumentsDelete] = React.useState(null);

  // lifted from https://github.com/mui/mui-x/issues/7607#issuecomment-1397220663
  const columns = React.useMemo(() => {
    // force use Memo to be have a dependency on these variables so they make this useMemo update.
    // should always be a positive number as sorting will be at least 0, rowModesMOdel will have at least
    // 0 keys, and pageSize will be at least 10
    let forceDependency = Object.keys(rowModesModel).length + paginationModel.pageSize + sorting.length;

    const actions_column = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick.bind(this, id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick.bind(this, id)}
              color="inherit"
            />,
          ];
        }
  
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick.bind(this, id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick.bind(this, id)}
            color="inherit"
          />,
        ];
      },
    };

    let columns = orderedColumns.reduce((acc, field) => {
      let columnInfo;

      if (field in table_columns) columnInfo = table_columns[field];
      else {
        columnInfo = {
          field: field,
          headerName: field,
          editable: true,
          width: 200
        }
      }

      return [...acc, columnInfo];
    }, []);

    if (columns.length === 0) return columns;
    
    const allowed_to_edit = props.userGroup.includes("capchd-edit") || props.userGroup.includes("capchd-admin");
    // console.log("columns memo allowed_to_edit?", allowed_to_edit);
    if (allowed_to_edit && forceDependency && !showTitle) columns.push(actions_column)

    return columns;
  }, [orderedColumns, rowModesModel, props.userGroup, sorting, paginationModel, showTitle]);

  const authToken = props.authToken;
  const apiRef = useGridApiRef();
  const classes = useStyles();

  const handlePaginationModelChange = useCallback((newPaginationModel) => {
    console.log("paginationModel changed to", newPaginationModel);
    setPaginationModel(newPaginationModel);
  }, []);

  useEffect(() => {
    console.log("rowModesModel changed to", rowModesModel);
  }, [rowModesModel]);

  useEffect(() => {
    console.log("userGroup changed to", props.userGroup);
  }, [props.userGroup]);

  useEffect(() => {
    console.log("sorting changed to", sorting);
    updateRows(props.table_name, sorting, "sorting");
  }, [sorting]);

  useEffect(() => {
    console.log("paginationModel changed to", paginationModel);
    updateRows(props.table_name, sorting, "paginationModel");
  }, [paginationModel]);

  useEffect(() => {
    console.log("props.activeFilters changed to", props.activeFilters);
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize
    });
    updateTable(props.table_name, sorting, true, "props.activeFilters");
  }, [props.activeFilters]);

  useEffect(() => {
    console.log("props.table_name changed to", props.table_name);
    setCount("<loading>");

    // reset cursors and page model for new table before calling updateTable
    setRowModesModel({});
    setPaginationModel(defaultPaginationModel);
    setSorting(defaultSorting);

    updateTable(props.table_name, sorting, true, "props.table_name");
  }, [props.table_name]);

  useEffect(() => {
    console.log("props.loaded changed to", props.loaded);
    setCount("<loading>");
    updateRows(props.table_name, sorting, true, "props.loaded");
  }, [props.loaded]);

  function CustomToolbar() {
    let table_name = props.table_name;
    let filter = localStorage.getItem("filter");
    let authToken = props.authToken;

    let patient_url = `${base_url_query}?cmd=query_from_table&table_name=${table_name}` +
      `&as_csv=1` +
      `&sorting=${sorting}`;

    const handleDownload = async () => {
      try {
        const response = await fetch(patient_url, {
          method: "POST",
          body: filter,
          headers: { Authorization: authToken, 'Content-Type': 'application/json' }
        });
  
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = blobURL;
        link.download = table_name + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("There was an error downloading the CSV:", error);
      }
    };

    return (
      <GridToolbarContainer>
      {/* Default client-side downloading (Download Page) */}
        {/* <GridToolbarExport 
        csvOptions={{
          fileName: table_name,
        }}/> */}
        <Button id="download-table-csv" variant="text" color="inherit" size="small" startIcon={<SaveAlt/>} onClick={handleDownload}>
          Download
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStart = (params, event) => {
      event.defaultMuiPrevented = true;
  };

  function CustomUnsortedIcon() {
    return <UnfoldMoreIcon sx={{fontSize: 20, color: "#009be5"}}/>;
  }

  function ColumnSortedAscendingIcon() {
    return  <ArrowUpwardIcon sx={{fontSize: 20, color: "#009be5"}}/>;
  }

  function ColumnSortedDescendingIcon() {
    return <ArrowDownwardIcon  sx={{fontSize: 20, color: "#009be5"}} />
  }

  const computeMutation = (newRow, oldRow) => {
    let headers = Object.keys(newRow);
    let changed = false;
    let count = 0;
    headers.forEach((element) => {
      if(newRow[element] !== oldRow[element] && element !== "age") {
        changed = true;
        count++;
      }
    })
    //console.log(count + " " + changed)
    return changed ? count : changed;
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleSaveClick = (id) => {
    props.setEditing(false);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id) => {
    let currentModel = {...rowModesModel};
    //console.log(currentModel);
    let rows = Object.keys(currentModel);
    let edit = true;
    if(rows.length >= 1) {
      //console.log(rows);
      rows.forEach((element) => {
        //console.log(element);
        if(currentModel[element].mode === GridRowModes.Edit) {
          props.setOpenAlert(16);
          edit = false;
        }
      });
    }
    if(edit){
      props.setEditing(true);
      currentModel[id] = {mode: GridRowModes.Edit};
      setRowModesModel(currentModel);
    }
    //console.log(currentModel);
  }

  const handleCancelClick = (id) => {
    //console.log(rows);
    setRowModesModel({...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true }});
    props.setEditing(false);
  }

  const handleDeleteClick = React.useCallback((id) => 
  new Promise((resolve, reject) => {
    let row = apiRef.current.getRow(id);
    setPromiseArgumentsDelete({resolve, reject, row})
  }),
  [apiRef])

  const handleConfirmEdit = React.useCallback((newRow, oldRow) =>
  new Promise((resolve, reject) => {
    if(oldRow) {
      let mutation = computeMutation(newRow, oldRow);

      if(mutation){
        setPromiseArguments({resolve, reject, newRow, oldRow})
      } else {
        resolve(oldRow);
      }
    }
  }),
  [])

  const handleSortModelChange = React.useCallback((sortModel) => {
    setSorting(sortModel[0] ? sortModel[0].field + " " + sortModel[0].sort : "ID asc");
  }, []);

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
      maxWidth="xs"
      TransitionProps={{ onEntered: handleEntered }}
      open={!!promiseArguments}
      sx={{".MuiDialogContent-dividers": {color: "white"}}}
      >
      <DialogTitle sx={{fontSize: 16, backgroundColor: "#081627", color: "white", borderBottomColor: "white", borderBottomWidth: "1px", borderBottomStyle: "solid" }}>Are you sure you want to save this row?</DialogTitle>
      <DialogContent sx={{backgroundColor: "#081627", color: "white", borderBottomColor: "white", borderBottomWidth: "1px", borderBottomStyle: "solid"}} dividers>
        {`Pressing 'Yes' will change ${mutation} field(s).`}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#081627", }}>
        <Button ref={noButtonRef} onClick={handleNo} sx={{color: "white", fontSize: 14}}>
          No
        </Button>
        <Button onClick={handleYes} sx={{color: "white", fontSize: 14}}>Yes</Button>
      </DialogActions>
      </Dialog>
    );
  };

  const renderConfirmDialogDelete = () => {
    if (!promiseArgumentsDelete) {
      return null;
    }

    const { row } = promiseArgumentsDelete;
    let age = row.age;
    let mutation = (age && age !== "None" && isNaN(age)) ? row.ID + "_" + age : row.ID;

    return (
      <Dialog
      maxWidth="xl"
      TransitionProps={{ onEntered: handleEntered }}
      open={!!promiseArgumentsDelete}
      sx={{".MuiDialogContent-dividers": {color: "white"}}}
      >
      <DialogTitle sx={{fontSize: 16, backgroundColor: "#081627", color: "white", borderBottomColor: "white", borderBottomWidth: "1px", borderBottomStyle: "solid" }}>Are you sure you want to delete this row?</DialogTitle>
      <DialogContent sx={{backgroundColor: "#081627", color: "white", borderBottomColor: "white", borderBottomWidth: "1px", borderBottomStyle: "solid"}} dividers>
        {`Pressing 'Yes' will delete row: ${mutation}`}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#081627", }}>
        <Button ref={noButtonRef} onClick={handleNoDelete} sx={{color: "white", fontSize: 14}}>
          No
        </Button>
        <Button onClick={handleYesDelete} sx={{color: "white", fontSize: 14}}>Yes</Button>
      </DialogActions>
      </Dialog>
    );
  };

  const handleEntered = () => {}

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;
      // Make the HTTP request to save in the backend
      let id = newRow.id;
      delete newRow.id;
      let newRowJSON = JSON.stringify(newRow);
      //newRowJSON = newRowJSON.slice(1, newRowJSON.length - 1); "gets rid of {} parts of string"
      let save_url = base_url_query + "?";
      let params = {"cmd":"put_item_in_table", "table_name":tableName, "fields":newRowJSON};
      save_url = save_url + new URLSearchParams(params);

      //console.log(newRowJSON);
      
      setPromiseArguments(null);
      setDeleteLoading(true);
      fetch( save_url, { headers: { Authorization: authToken }, method: 'GET'})
      .then(response => {
        //console.log(response);
        if(!response.ok){
          throw Error(response.statusText);
        }
      })
      .then(data => {
        //console.log(data);
        setDeleteLoading(false);
        newRow.id = id;
        newRow.age = oldRow.age;
        resolve(newRow);
        setSnackbar({ children: 'Successfully saved', severity: 'success', title: "Success" });
      })
      .catch((err)=> {
        //console.log(err);
        setDeleteLoading(false);
        setSnackbar({ children: "There was an error with saving", severity: 'error', title: "Error" });
        resolve(oldRow);
      })

  };

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); 
    setPromiseArguments(null);
  };

  const handleYesDelete = () => {
    const { row, reject, resolve } = promiseArgumentsDelete;
    let deletedRow = row

    //console.log(deletedRow);
    let deletedRowJSON = JSON.stringify(deletedRow);
    let deleteURL = base_url_query + "?";
    let params = {"cmd":"remove_item_from_table", "table_name":tableName, "fields":deletedRowJSON};
    deleteURL = deleteURL + new URLSearchParams(params);

    setDeleteLoading(true);
    setPromiseArgumentsDelete(null);
    fetch( deleteURL, { headers: { Authorization: authToken }, method: 'GET'})
    .then(response => {
      //console.log(response);
      if(!response.ok){
        throw Error(response.statusText);
      }
    })
    .then(data => {
      setDeleteLoading(false);
      setRows(rows.filter((row) => row.id !== deletedRow.id));
      resolve(row);
      setSnackbar({ children: 'Successfully deleted.', severity: 'success', title: "Success" });
    })
    .catch((err)=> {
      //console.log(err);
      setDeleteLoading(false);
      setSnackbar({ children: "There was an error with deletion", severity: 'error', title: "Error" });
      resolve(row);
    })
  }

  const handleNoDelete = () => {
    const { row, resolve } = promiseArgumentsDelete;
    resolve(row); 
    setPromiseArgumentsDelete(null);
  };

  // lifted from https://github.com/mui/mui-x/issues/7607#issuecomment-1397220663
  const handleColumnOrderChange = React.useCallback((params) => {
    setOrderedColumns((prevOrderedColumns) => {
      const newOrderedColumns = [...prevOrderedColumns];
      const oldIndex = params.oldIndex;
      const targetIndex = params.targetIndex;
      // If you have `checkboxSelection` you need to subtract 1 from both indexes above
      const oldColumn = prevOrderedColumns[oldIndex];
      newOrderedColumns.splice(oldIndex, 1);
      newOrderedColumns.splice(targetIndex, 0, oldColumn);
      return newOrderedColumns;
    });
  }, []);

  function renderHeader(column_names, calling_function="NA") {
    // console.log(calling_function, "renderHeader called");
    // console.log(calling_function, "renderHeader columns:", column_names)
    setOrderedColumns(column_names);
  }

  function renderRows(rows, calling_function="NA") {
    // console.log(calling_function, "renderRows called");
    for (let i = 0; i < rows.length; i++) {
      let age_str = rows[i]["age"] ? `-${rows[i]["age"]}` : "";
      rows[i]["id"] = rows[i]["ID"] + age_str;
    }
    
    setRows(rows);
  }

  function updateRows(table_name, sorting, calling_function="NA") {
    // console.log(calling_function, "updateRows called");
    updateTable(table_name, sorting, false, calling_function);
  }

  /**
   * This function attempts to fetch data from a given table and sorted using the given method.
   * May alter the showTitle state.
   * @param {string} table_name table name to retrieve data from
   * @param {string} sorting sorting method
   * @returns a Promise containing data
   */
  async function fetchData(table_name, sorting, calling_function="NA") {
    // console.log(calling_function, "fetchData called");
    // console.log(calling_function, "fetchData page", paginationModel.page, "size", paginationModel.pageSize);
    if (!valid_tables.includes(table_name)) return null;

    let patient_url = "";
    let filter;

    if (props.patient_id === undefined || props.patient_id === "") {
      filter = localStorage.getItem("filter");

      patient_url = `${base_url_query}?cmd=query_from_table&table_name=${table_name}` +
        `&which_page=${paginationModel.page}` +
        `&size=${paginationModel.pageSize}`+
        `&sorting=${sorting}`;
    } else {
      setShowTitle(true);
      filter = props.patient_id

      patient_url = `${base_url_query}?cmd=query_from_table&table_name=${table_name}` +
        `&which_page=${paginationModel.page}` +
        `&size=${paginationModel.pageSize}`;
      props.FilterUpdate(false);
    }

    return await fetch(patient_url, {
        method: "POST",
        body: filter,
        headers: { Authorization: authToken, 'Content-Type': 'application/json' }
      })
      .then((response) => response.json() )
      .then((responseData) => {
        if(responseData.message === "The incoming token has expired") props.logoutCallback(true);

        return responseData;
      })
      .catch((err) => {
        console.log("ERROR:", err)
      });
  }

  async function updateTable(table_name, sorting, updateHeader=true, calling_function="NA") {
    // console.log(calling_function, "updateTable called");
    if (!valid_tables.includes(table_name)) {
      console.err("INVALID TABLE NAME.");
      return;
    }

    setLoading(true);
    setShowTitle(false);

    let responseData = await fetchData(table_name, sorting, calling_function);

    if (responseData === null || responseData === undefined) {
      setOrderedColumns([]);
      setRows([]);
      setCount('<None>');
      return;
    }

    let column_names = responseData[0];
    let row_data = responseData[1];
    let total_items = responseData[2];

    // console.log(calling_function, "updateTable row_data:", row_data)

    if (column_names !== undefined && column_names.length > 1) {
      if (updateHeader) renderHeader(column_names, calling_function);
      renderRows(row_data, calling_function);
    } else {
      console.log("No column names");
      setOrderedColumns([]);
      setRows([]);
      setCount('<None>');
    }

    setCount(total_items);
    setRowCount(total_items);

    setTableName(table_name);
    setLoading(false);
  }

  return (
    <>
    {count === "<loading>" ? 
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <ReactLoading type="spin" color="#009be5" height={667} width={375} />
      </div>
    :
      <div style={{ height: showTitle === true ? count !== 0 ? 300 : 0 : 700, marginBottom: 30, paddingBottom: showTitle ? 20 : 0}} className={classes.root}>
        {showTitle === true ?
          <h4 style={{fontSize: 16, fontWeight: 700, textTransform: "uppercase", marginBottom: 5}}>
            {tableName} {count === 0 && <span style={{textTransform: "capitalize", fontWeight: 400}}>- no records found</span>}
          </h4>
          : <></>
        }
        
        {renderConfirmDialog()}
        {renderConfirmDialogDelete()}
        {showTitle ? count !== 0 ? 
        <DataGridPro
          loading={deleteLoading || loading}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          sortingOrder={['asc','desc']}
          suppressMultiSort
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          apiRef={apiRef}
          pageSize={paginationModel.pageSize}
          onPageSizeChange={(newPageSize) => 
            handlePaginationModelChange({ ...paginationModel, page: 0, pageSize: newPageSize })
          }
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          pagination
          paginationMode="server"
          onPageChange={(newPage) =>
            handlePaginationModelChange({ ...paginationModel, page:newPage})
          }
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          onColumnOrderChange={handleColumnOrderChange}
          //onCellDoubleClick={onCellDoubleClick}
          localeText={{
            toolbarExport: "Download page"
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            ColumnUnsortedIcon: CustomUnsortedIcon,
            ColumnSortedAscendingIcon: ColumnSortedAscendingIcon,
            ColumnSortedDescendingIcon: ColumnSortedDescendingIcon
          }}
          initialState={{
            pinnedColumns: { left: [], right: ['actions'] },
            sorting: {
              sortModel: [{ field: 'ID', sort: 'asc' }],
            },
          }}
          rowModesModel={rowModesModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={handleConfirmEdit}
          experimentalFeatures={{ newEditingApi: true }}
          editMode="row"
          getRowId={(row) => row.id}
          getRowHeight={() => {
            if(tableName === "ClinicalNotes") {
              return 'auto';
            }

            return null;
          }}
        /> : <></> : 
        <DataGridPro
          loading={deleteLoading || loading}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          sortingOrder={['asc','desc']}
          suppressMultiSort
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          apiRef={apiRef}
          pageSize={paginationModel.pageSize}
          onPageSizeChange={(newPageSize) => 
            handlePaginationModelChange({ ...paginationModel, page: 0, pageSize: newPageSize })
          }
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          pagination
          paginationMode="server"
          onPageChange={(newPage) =>
            handlePaginationModelChange({ ...paginationModel, page:newPage})
          }
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          onColumnOrderChange={handleColumnOrderChange}
          //onCellDoubleClick={onCellDoubleClick}
          localeText={{
            toolbarExport: "Download page"
          }}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
            ColumnUnsortedIcon: CustomUnsortedIcon,
            ColumnSortedAscendingIcon: ColumnSortedAscendingIcon,
            ColumnSortedDescendingIcon: ColumnSortedDescendingIcon
          }}
          initialState={{
            pinnedColumns: { left: [], right: ['actions'] },
            sorting: {
              sortModel: [{ field: 'ID', sort: 'asc' }],
            },
          }}
          rowModesModel={rowModesModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={handleConfirmEdit}
          experimentalFeatures={{ newEditingApi: true }}
          editMode="row"
          getRowId={(row) => row.id}
          getRowHeight={() => {
            if(tableName === "ClinicalNotes") {
              return 'auto';
            }

            return null;
          }}
        />}
        {!!snackbar && (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open onClose={handleCloseSnackbar} autoHideDuration={6000} >
          <Alert {...snackbar} sx={{"& .MuiAlert-icon": { fontSize: 40 }}} onClose={handleCloseSnackbar} style={{fontSize: 16}}>
            <AlertTitle  style={{fontSize: 20,  }}>{snackbar.title}</AlertTitle>
            {snackbar.children}
          </Alert>
        </Snackbar>
        )}
      </div>
    }
    </>
  );
}
export default MyTable